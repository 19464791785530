.container {
  height: 100vh;
  width: 100%;
  background-image: url("./background.png");
  background-position: center;
  background-size: cover;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
  position: relative;
}

.navbar {
  width: 100%;
  height: 15vh;
  margin: auto;
  display: flex;
  align-items: center;
}
.logo {
  width: 160px;
  cursor: pointer;
}

.content h1 {
  font-size: 60px;
  font-weight: 100;
  margin-top: 24px;
  margin-bottom: 15px;
  color: #0f138c;
  font-family: "century_gothicregular";
}
.content p {
  font-size: 20px;
  color: #0f138c;
  font-family: "century_gothicregular";
}
.content {
  margin-left: 10%;
  margin-top: 10%;
}
.content .btn {
  display: inline-block;
  border-radius: 6px;
  font-size: 53px;
  font-weight: bold;
  box-sizing: border-box;
  text-decoration: none;
  color: #0f138c;
  font-family: "gothambold";
  border: none;
  background-color: transparent;
}

.arrow-icons {
  margin-top: 40px;
  display: flex;
}
.arrow-icons img {
  width: 40px;
  margin-right: 25px;
}

.featured-image {
  width: 40%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.social-links {
  transform: rotate(-90deg);
  position: absolute;
  left: -80px;
  bottom: 180px;
}

.social-links a {
  text-decoration: none;
  color: #6a7199;
  padding-right: 20px;
  font-size: 14px;
}

@media (max-width: 1190px) {
  .content {
    margin-left: 0%;
  }
}
@media (max-width: 1070px) {
  .content h1 {
    font-size: 35px;
  }
  .content p {
    font-size: 12px;
  }
  .content .btn {
    font-size: 32px;
  }
}
@media (max-width: 890px) {
  .featured-image {
    max-width: 100%;
    width: 100%;
    bottom: -80%;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0%;
  }
  .content h1 {
    text-align: center;
    font-size: 50px;
  }
  .content {
    font-size: 20px;
  }
  .content .btn {
    font-size: 42px;
  }
  .navbar {
    justify-content: center;
  }
}
@media (max-width: 440px) {
  .navbar {
    height: auto;
    padding-top: 10%;
  }
  .logo {
    width: 109px;
  }
  .featured-image {
    bottom: -40%;
  }

  .content h1 {
    text-align: center;
    font-size: 30px;
  }
  .content .btn {
    font-size: 32px;
  }
}
