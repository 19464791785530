.hero-section-img-container {
  margin: 10px 0 23px 0;
}
.hero-section-image {
  max-width: 127px;
}
.hero-section-typo {
  max-width: 722px;
}

.hero-section-typo-light {
  width: 554px;
  text-align: center;
  font: normal normal normal 92px/119px Century Gothic;
  font-family: "century_gothicregular";
  letter-spacing: -2.3px;
  color: #0f138c;
  margin-bottom: 22px;
}

.hero-section-typo-light span {
  width: 554px;
  height: 228px;

  text-align: center;
  font: normal normal normal 92px/119px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -2.3px;
  color: #0f138c;
}
.hero-section-typo h2 {
  text-align: center;
  font: normal normal bold 30px Century Gothic;
  font-family: "century_gothicbold";
  color: rgba(15, 19, 140, 1);
}

.mid-typo-container {
  width: 962px;
  text-align: center;
  letter-spacing: 5px;
  color: #0f138c;
  text-transform: uppercase;
  margin-bottom: 22px;
}
.mid-typo-container span {
  font-family: "century_gothicregular";
}
.hero-section-second-typo {
  max-width: 594px;
  padding: 14px 23px;
  text-align: center;
  background: #f4f4fd 0% 0% no-repeat padding-box;
  margin-top: 13.5px;
}
.hero-section-second-typo h3 {
  /* width: 688px; */
  font: normal normal normal 15px/20px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: 0px;
  color: rgba(15, 19, 140, 1);
  opacity: 1;
}
