/* Gotham font  */
@font-face {
  font-family: "gothambold";
  src: url("/public/assets/fonts/gotham/gothambold-webfont.woff2") format("woff2"),
    url("/public/assets/fonts/gotham/gothambold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("/public/assets/fonts/gotham/gothambook-webfont.woff2") format("woff2"),
    url("/public/assets/fonts/gotham/gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold";
  src: url("/public/assets/fonts/gotham/gothammedium-webfont.woff2") format("woff2"),
    url("/public/assets/fonts/gotham/gothammedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Century gothic font  */

@font-face {
  font-family: "century_gothicregular";
  src: url("/public/assets/fonts/centuryGothic/gothic-webfont.woff2") format("woff2"),
    url("/public/assets/fonts/centuryGothic/gothic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "century_gothicbold";
  src: url("/public/assets/fonts/centuryGothic/gothicb-webfont.woff2") format("woff2"),
    url("/public/assets/fonts/centuryGothic/gothicb-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.qxCTlb {
  font-size: "18px";
  font-family: "century_gothicregular";
}

.visibility-none {
  visibility: hidden !important;
}

.BzuSBc {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 72px !important;
  padding-bottom: 0px !important;
}

.gothic-regular {
  font-family: "century_gothicregular";
}

.gothic-bold {
  font-family: "century_gothicbold";
}

.font-gotham-bold {
  font-family: "gothambold";
}

.font-gotham-normal {
  font-family: "gothambook";
}

.font-gotham-semi-bold {
  font-family: "gothambold";
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.TUIr4b {
  font-family: "century_gothicregular" !important;
}

.render-animation {
  animation-name: render-animation;
  animation-duration: 0.5s;
}

@keyframes render-animation {
  0% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

img {
  width: 100%;
  max-width: 100%;
}

button {
  cursor: pointer;
  background-color: transparent;
}

button:active {
  transform: scale(98%);
  transition: 0.3s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 30px 2px 2px;
  border: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.width {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.vhHeight {
  min-height: 100vh;
  max-height: auto;
}

.height {
  height: 100%;
}

.my_row {
  display: flex;
  flex-wrap: wrap;
}

.justified {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.align_start {
  align-items: start;
}

.align_end {
  align-items: end;
}

.align_center {
  align-items: center;
}

.justify_start {
  justify-content: start;
}

.justify_end {
  justify-content: end;
}

.space_between {
  justify-content: space-between;
}

.space_around {
  justify-content: space-around;
}

.justify_center {
  justify-content: center;
}

.flex_column {
  flex-direction: column;
}

.slider-prev-next-buttons-content {
  gap: 241px;
  /* margin: 61px 0 35px 0; */
}

.continue-or-report-main {
  margin-top: 50px;
  margin-bottom: 190px;
}

.click-capture {
  position: relative;
}

.click-capture::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.home-content {
  display: flex;
}

.home-content>div {
  width: 50% !important;
}

.logo-container {
  margin-bottom: 70px !important;
}

.home-left-typ-heading p {
  font-size: 40px;
}

/* Loading icon  */

@media (max-width: 767px) {
  .hero-section-img-container {
    margin: 25px 0 !important;
  }

  .hero-section-typo h2 {
    font-size: 26px !important;
    padding: 0 10px;
  }

  .hero-section-second-typo h3 {
    padding: 0 10px;
    font-size: 14px !important;
  }

  .hero-section-second-typo {
    padding: 0 !important;
  }

  .typo-container {
    margin-bottom: 30px !important;
  }

  .gtn-input-main {
    max-width: 430px !important;
    width: auto !important;
    margin-left: 10px;
    margin-right: 10px;
    height: 54px !important;
  }

  .slide-content-container {
    min-height: auto !important;
    margin-bottom: 30px !important;
  }

  .slider-prev-next-buttons-content {
    gap: 20px !important;
    flex-direction: column-reverse;
  }

  .bottom-section-main {
    margin-bottom: 30px !important;
  }

  .gtn-ico {
    width: 54px !important;
    height: 54px !important;
  }

  .gtn-ico img {
    max-width: 30px !important;
  }

  .gtn-button .label-button {
    width: 47px !important;
    height: 24px !important;
  }

  .gtn-input {
    font-size: 16px !important;
  }

  .card-renderer-content {
    width: 45%;
  }

  .card-renderer-button {
    width: 100% !important;
    height: 175px !important;
  }

  .card-renderer-button-caption {
    width: 100% !important;
  }

  .form-input-main-container {
    /* width: 100% !important;
    height: auto !important; */
  }

  .label-container {
    width: 90% !important;
  }

  .form-input-main-container {
    width: 90% !important;
  }

  .receive-results-button {
    width: 200px !important;
    height: 53px !important;
    font-size: 16px !important;
  }

  .contact-icon-container {
    width: 45px !important;
    height: 45px !important;
    margin: 0 6px !important;
  }

  .selected-method {
    border: 1px solid #0f138c !important;
  }

  .second-input {
    width: 70% !important;
  }

  .country-img.justified {
    display: none;
  }

  .country-code-container {
    width: 50px !important;
    height: 38px !important;
    background-color: transparent !important;
  }

  .phone-number-input {
    width: 53% !important;
    font-size: 23px !important;
  }

  .checkbox-container.justified {
    margin-left: 5px;
  }

  .fiels-empty-err-container {
    bottom: 0 !important;
    width: 50% !important;
    padding: 20px;
    border-radius: 10px;
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    transition: 0.3s;
  }
}

@media (max-width: 970px) {
  .logo-container img {
    max-width: 107px !important;
  }

  .mid-typo-container {
    width: 100% !important;
  }

  .hero-section-second-typo {
    width: 100% !important;
  }

  .hero-section-typo-light span {
    width: auto !important;
    height: auto !important;
    font-size: 28px !important;
  }

  .hero-section-typo-light {
    width: 100% !important;
  }

  .home-content {
    display: block;
  }

  .home-content>div {
    width: auto !important;
  }

  .home-left-inner {
    margin: 0 !important;
    margin-bottom: 40px !important;
    text-align: center !important;
  }

  .logo-container {
    padding: 30px 0 !important;
    margin: 0 !important;
  }

  .home-left-typ-heading p {
    font-size: 32px !important;
    text-align: center;
  }

  .home-left-typ-content {
    text-align: center !important;
  }

  .home-left-typ-heading p {
    font-size: 40px !important;
  }

  .home-left-typ-content p {
    font-size: 16px !important;
  }

  .home-left-typ-heading {
    line-height: 50px !important;
  }

  .home-left-typ-content {
    margin-bottom: 30px !important;
  }

  .start-button {
    margin: auto !important;
    font-size: 24px !important;
    text-align: center !important;
  }

  .assets-bg {
    background-size: cover !important;
  }
}