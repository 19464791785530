.get-results-main {
  gap: 20px;
}
.get-results-content {
  gap: 20px;
}
.receive-results-button {
  width: 277.6px;
  height: 78.4px;
  border-radius: 20px;
  text-align: left;
  font: normal normal bold 21px Century Gothic;
  font-family: "century_gothicbold";
  /* letter-spacing: -0.75px; */
  color: #000000;
  opacity: 1;
  border: none;
}
.selected-method {
  border: 2px solid #000000;
}

.contact-icon-container {
  width: 52.2px;
  height: 52.2px;
  border-radius: 50%;
  background-color: #0f138c;
  margin: 0 20px;
}
.contact-icon-container img {
  max-width: 50%;
}

.form-input-main-container {
  width: 445px;
  height: 70px;
  /* UI Properties */
  background: #e6e6f5 0% 0% no-repeat padding-box;
  border-radius: 37px;
  /* padding: 14px; */
}

.otp-action-button {
  width: 91px;
  height: 38px;

  border-radius: 19px;
  opacity: 1;

  text-align: center;
  font: normal normal bold 13px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.33px;
}

.enabled {
  background: #0f138c 0% 0% no-repeat padding-box;
  border: 2px solid #0f138c;
  color: #ffffff;
}

.country-code-container {
  width: 23%;
  height: 46px;

  background: #f7f7fc 0% 0% no-repeat padding-box;
  border-radius: 23px;

  text-align: left;
  font: normal normal normal 20px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.5px;
  color: #0f138c;
  opacity: 0.7;
  gap: 5px;
  border: none;
}

.country-img {
  max-width: 35px;
}

.phone-number-input {
  /* width: 54%; */
  width: 50%;
  border: none;
  background-color: transparent;

  text-align: left;
  font: normal normal normal 32px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.8px;
  color: #0f138c;
}
.second-input {
  box-sizing: border-box;
  padding-left: 40px;

  text-align: left;
  font: normal normal normal 20px/92px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.5px;
  color: #0f138c;
  opacity: 0.7;
  background-color: transparent;
  border: none;
  /* width: 80%; */
  flex: 1;
}
.phone-number-input::placeholder,
.second-input::placeholder {
  color: #0f138c;
}
.reset-otp-button .refresh-icon-contianer {
  width: 22px;
  height: 22px;
  background: #0f138c 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 2px;
}

.reset-otp-button {
  text-align: left;
  font: normal normal normal 20px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.5px;
  color: #0f138c;
  opacity: 0.7;
  gap: 5px;
  border: none;
}

.form-input-main-container {
  margin-bottom: 15px;
  padding: 0 12px;
}

.get-result-form {
  margin-top: 35px;
}
.checkbox-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.checkbox {
  width: 27px;
  height: 27px;
}
.label-container {
  width: 422px;
}
.checkboxLabel {
  text-align: left;
  font: normal normal bold 20px/22px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.5px;
  color: #0f138c;
  opacity: 0.7;
}

.resend-otp-button-containe {
  margin: 30px 0 35px 0;
  gap: 5px;
}

.form-response {
  margin-top: 16px;
}
.form-response span {
  font: normal normal bold 20px/22px Century Gothic;
  font-family: "century_gothicregular";
}

.form-response .went-wrong {
  color: #e10505;
}
.form-response .submit-success {
  color: #e10505;
}
