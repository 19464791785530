.card-renderer-content {
  /* min-height: 411px; */
  align-items: start;
  justify-content: start;
  border-radius: 20px 20px 20px 20px;
  padding: 0 6px;
  transition: 0.3s;
}
.selected {
  background-color: #0f138c;
}
.selected button {
  background-color: #0f138c;
  border-color: #0f138c;
}
.card-renderer-button-caption,
.card-renderer-typo {
  padding: 0 5px;
  box-sizing: border-box;
  transition: 0.3s;
}
.selected .card-renderer-button-caption,
.selected .card-renderer-typo {
  color: #fff;
}

.not-selected {
  background-color: transparent;
}
.card-renderer-inner {
  /* margin-top: 68px; */
  gap: 8px;
  /* padding: 55px 0 60px 0; */
}
.card-renderer-button {
  width: 148.4px;
  height: 192.5px;
  border: 2px solid #000000;
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  transition: 0.3s;
}
.card-renderer-button img {
  max-width: 127px;
}
.card-renderer-button-caption {
  text-align: center;
  font: normal normal bold 15px/18px Century Gothic;
  font-family: "century_gothicbold";
  letter-spacing: 0.7px;
  color: #000000;
  width: 148.4px;
  margin: 15px 0 8px 0;
}
.card-renderer-typo {
  width: 208px;
  text-align: center;
  font: normal normal normal 16px/20px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.4px;
  color: #707070;
  margin: 0 0 10px 0;
}
