.fiels-empty-err-container {
  bottom: 0;
  width: 30%;
  padding: 20px;
  border-radius: 10px;
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  transition: 0.3s;
}
