.common-button {
  padding: 9px 42px;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 13px Century Gothic;
  font-family: "century_gothicregular";
  gap: 8px;
}
.border-button {
  border: 1px solid #0f138c;
}
.common-button img {
  max-width: 20px;
}
.filled {
  background: #0f138c 0% 0% no-repeat padding-box;
  color: #ffffff;
}
.text-only {
  background: transparent;
  color: #0f138c;
}

.disabled {
  background-color: #2c2c2f;
  color: #ffffff;
  border-color: #2c2c2f;
}
