.progress-bar-inner {
  width: 926px;
  margin-top: 16px;
}
.progress-bar-progress {
  border: 4px solid rgba(15, 19, 140, 1);
  opacity: 1;
  border-radius: 5px;
  transition: 0.5s;
}
.progress-bar-progress-percent {
  margin-top: 6px;
}
.progress-bar-progress-percent span {
  width: 8px;
  height: 13px;
  text-align: right;
  font: normal normal bold 12px Gotham;
  letter-spacing: -0.3px;
  color: rgba(15, 19, 140, 1);
}

