.gtn-input-main {
  width: 330px;
  height: 50px;
  background: rgba(230, 230, 245, 1) 0% 0% no-repeat padding-box;
  border-radius: 37px;
  opacity: 1;
  margin-bottom: 9px;
  outline: 2px solid transparent;
  transition: 0.3s;
  position: relative;
}
.focused-input {
  outline-color: rgba(15, 19, 140, 1);
}
.blured-input {
  outline-color: rgba(230, 230, 245, 1);
}
.gtn-ico {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(15, 19, 140, 1);
  position: absolute;
}
.gtn-ico img {
  max-width: 25px;
}
.gtn-input {
  font: normal normal normal 16px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.5px;
  color: rgba(15, 19, 140, 1);
  opacity: 0.7;
  background-color: transparent;
  padding: 0 95px 0 62px;

  box-sizing: border-box;
  border: none;
}
.gtn-input-width {
  width: 100%;
}
.gtn-input-dropdown-width {
  width: 100%;
}
.gtn-input::placeholder {
  letter-spacing: -0.5px;
  color: rgba(15, 19, 140, 1);
}
.gtn-button .label-button {
  width: 46.9px;
  height: 22.4px;
  text-align: center;
  font: normal normal bold 11px Century Gothic;
  font-family: "century_gothicregular";
  letter-spacing: -0.33px;
  color: rgba(15, 19, 140, 1);
  border: 2px solid rgba(15, 19, 140, 1);
  background-color: transparent;
  border-radius: 16px;
}
.gtn-button {
  position: absolute;
  right: 17px;
}

.gtn-button .dropdown-button {
  width: 32px;
  height: 32px;
  text-align: center;
  font: normal normal bold 13px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.33px;
  color: rgba(15, 19, 140, 1);
  border: 2px solid rgba(15, 19, 140, 1);
  border-radius: 50%;
  margin-right: 17px;
}
