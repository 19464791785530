.numbers-button-main {
  margin-top: 25px;
}
.numbers-inner {
  gap: 10px;
}
.numbers-button {
  border: 2px solid #0f138c;
  border-radius: 5px;
  font: normal normal bold 23px Century Gothic;
  font-family: "century_gothicregular";

  letter-spacing: -0.75px;
  color: #0f138c;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
