.psoc-risk-score .chart-label {
  text-align: center;
  font: normal normal bold 32px Century Gothic;
  font-family: "century_gothicregular";
  letter-spacing: 0.8px;
  color: #0f138c;
}

.psoc-risk-score span {
  text-align: center;
  font: normal normal bold 42px Century Gothic;
  font-family: "century_gothicregular";
  color: #0f138c;
  background-color: #f4f4fd;
  /* background-color: green; */

  padding: 5px;
  margin-left: 5px;
  text-align: center;
}

.risk-frequency-text {
  font: normal normal bold 16px/52px Century Gothic;
  font-family: "century_gothicregular";
  letter-spacing: -0.55px;
  color: #fff;
}
.chart-size {
  width: 550px !important;
}

/* typography  */
.score {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
}
.score-right {
  flex: 1;
}

.score-type-heading {
  color: #d42588;
}

.score-type-heading,
.score-range {
  font-size: 28px;
  font-weight: bold;
  font-family: "century_gothicbold";
}

.green {
  color: #8cd47e;
}
.yellow {
  color: #f8d66d;
}
.red {
  color: #ff6961;
}
.score-description {
  display: inline;
  font-size: 18px;
  font-family: "century_gothicregular";
  color: #0f138c;
}
.disclaimer-main,
.score-guide {
  background-color: #f4f4fd;
  padding: 18px 36px;
}

.disclaimer-main {
  margin-bottom: 30px;
}
.disclaimer-heading {
  font-family: "century_gothicbold";
  font-size: 20px;
  color: #d42588;
  margin-bottom: 8px;
}
.disclaimer-typo {
  font-size: 18px;
  font-family: "century_gothicregular";
  color: #0f138c;
}
.score-left {
  flex: 1;
}
.score-right {
  flex: 9;
}
.refButton {
  display: none;
}

.range-1 {
  left: 17%;
  bottom: 21%;
}
.range-2 {
  top: 30px;
}
.range-3 {
  right: 17%;
  bottom: 21%;
}
@media (max-width: 1003px) {
  .score {
    flex-direction: column;
  }
  .score-left {
    width: 100%;
  }
  .score-right {
    width: 100%;
  }
}

@media (max-width: 488px) {
  .chart-size {
    width: 350px !important;
  }
  .psoc-risk-score .chart-label {
    font-size: 18px;
  }
  .psoc-risk-score span {
    font-size: 25px;
  }
  .risk-frequency-text {
    font-size: 11px;
  }
  .range-2 {
    top: 10px;
  }
  .range-1,
  .range-3 {
    bottom: 14%;
  }
}
